import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const browser = typeof window !== "undefined" && window;

const NotFoundPage = () => (
  browser && <Layout>
    <SEO title="404: Not found" />
    <h1>Sivua ei löytynyt!</h1>
    <p>Voi ei. Etsimääsi sivua ei löytynyt.</p>

    <h1>Page not found!</h1>
    <p>Oh no. Unfortunately the page you were looking at was not found.</p>
  </Layout>
)

export default NotFoundPage
